import videojs from 'video.js'
import videojsEvents from '../../../../configs/videoJsEvents'
import ResolutionMenuItem from './ItemComponent'

const MenuButton = videojs.getComponent('MenuButton')

var ResolutionMenuButton = videojs.extend(MenuButton, {
  constructor: function (player, options) {
    this.label = document.createElement('span')
    options.label = 'Quality'
    // Sets this.player_, this.options_ and initializes the component
    MenuButton.call(this, player, options)
    this.el().setAttribute('aria-label', 'Quality')
    this.controlText('Quality')

    if (options.dynamicLabel) {
      videojs.dom.addClass(this.label, 'vjs-resolution-button-label')
      this.el().appendChild(this.label)
    } else {
      const staticLabel = document.createElement('span')
      videojs.dom.addClass(staticLabel, 'vjs-menu-icon')
      this.el().appendChild(staticLabel)
    }

    player.on(videojsEvents.ResolutionChange, videojs.bind(this, this.update))
  }
})

ResolutionMenuButton.prototype.createItems = function () {
  const labels = (this.sources && this.sources.label) || {}

  return Object.keys(labels)
    .filter(key => labels.hasOwnProperty(key))
    .map(key => new ResolutionMenuItem(
      this.player_,
      {
        label: key,
        resolution: labels[key].resolution,
        src: labels[key],
        selected: (this.currentSelection ? this.currentSelection.label : false) === key
      }
    ))
}

ResolutionMenuButton.prototype.update = function () {
  this.sources = this.player_.getGroupedSrc()
  this.currentSelection = this.player_.currentResolution()

  if (this.currentSelection !== null) {
    this.label.innerHTML = this.currentSelection.label
  }

  return MenuButton.prototype.update.call(this)
}

ResolutionMenuButton.prototype.buildCSSClass = function () {
  return MenuButton.prototype.buildCSSClass.call(this) + ' vjs-resolution-button'
}

MenuButton.registerComponent('ResolutionMenuButton', ResolutionMenuButton)

export default ResolutionMenuButton
