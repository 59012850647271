export default {
  VolumeChange: 'volumechange',
  ContentLoadedMetaData: 'contentloadedmetadata',
  CanPlay: 'canplay',
  Play: 'play',
  Click: 'click',
  PageHide: 'pagehide',
  Vast: {
    AdSkip: 'vast.adSkip',
    AdError: 'vast.adError',
    AdStart: 'vast.adStart',
    AdMute: 'vast.adMute',
    AdEnd: 'ended',
  },
  Vpaid: {
    AdSkip: 'AdSkipped',
    AdStart: 'AdStarted',
    AdStopped: 'AdStopped',
    AdClickThru: 'AdClickThru',
    AdPaused: 'AdPaused',
    AdPlaying: 'AdPlaying',
    AdError: 'AdError',
    AdEnd: 'AdVideoComplete',
    AdVolumeChange: 'AdVolumeChange',
    AdVideoStart: 'AdVideoStart',
    AdVideoFirstQuartile: 'AdVideoFirstQuartile',
    AdVideoMidpoint: 'AdVideoMidpoint',
    AdVideoThirdQuartile: 'AdVideoThirdQuartile',
    AdVideoComplete: 'AdVideoComplete',
    AdImpression: 'AdImpression',
  },
  Dispose: 'dispose',
  TimeUpdate: 'timeupdate',
  ResolutionChange: 'resolutionchange',
  UpdateSources: 'updateSources',
}
