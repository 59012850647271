import videojs from 'video.js'

import videojsEvents from '../../../../configs/videoJsEvents'
import storage from '../../utils/storage'
import * as storageKeys from '../../../../configs/storageKeys'

const MenuItem = videojs.getComponent('MenuItem')

const ResolutionMenuItem = videojs.extend(MenuItem, {
  constructor: function (player, options) {
    options.selectable = true
    // Sets this.player_, this.options_ and initializes the component
    MenuItem.call(this, player, options)
    this.src = options.src

    player.on(videojsEvents.UpdateSources, videojs.bind(this, this.update))
  }
})

ResolutionMenuItem.prototype.handleClick = function (event) {
  MenuItem.prototype.handleClick.call(this, event)
  this.player_.currentResolution(this.options_.resolution)

  if (this.player_.paused() === false) {
    this.player_.play()
  }
}

ResolutionMenuItem.prototype.update = function () {
  const chosen = this.player_.currentResolution().sources.resolution
  storage.set(storageKeys.ResolutionSwitcher.ChosenUserResolution, chosen)

  this.selected(this.options_.resolution === chosen)
}

MenuItem.registerComponent('ResolutionMenuItem', ResolutionMenuItem)

export default ResolutionMenuItem
