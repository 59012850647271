// session store with watch
import engine from 'store/src/store-engine'

import localStore from 'store/storages/localStorage'
import cookieStore from 'store/storages/cookieStorage'
import memoryStore from 'store/storages/memoryStorage'

import defaultPlugin from 'store/plugins/defaults'
import expiredPlugin from 'store/plugins/expire'

const storages = [localStore, cookieStore, memoryStore]
const plugins = [defaultPlugin, expiredPlugin]

const storeEngine = engine.createStore(storages, plugins)

export default storeEngine
