import videojs from 'video.js'

//import Ad from './Ad'
import VolumePersister from './VolumePersister'
import ResolutionSwitcher from './ResolutionSwitcher'

//videojs.registerPlugin('Ad', Ad)
videojs.registerPlugin('VolumePersister', VolumePersister)
videojs.registerPlugin('ResolutionSwitcher', ResolutionSwitcher)

let options = {
  controls: true,
  autoplay: false,
  preload: 'metadata',
  fluid: true,
  aspectRatio: '16:9',
  plugins: {
    ResolutionSwitcher: {
      dynamicLabel: true,
    },
    VolumePersister,
    /*Ad: {
      urls: [
        'https://protovid.com/j/v3.php?id=1496'
      ],

      vastEnabled: true,
      vpaidEnabled: true,
      maxAdShowsNum: 1,
      replayAfter: 43200
    }*/
  }
}

export default options