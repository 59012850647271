'use strict'

import videojs from 'video.js'

import ResolutionMenuButton from '../uiComponents/resolutionMenu/MenuButtonComponent'
import resolutionSwitcherDefaults from '../../../configs/resolutionSwitcherDefaults'
import videojsEvents from '../../../configs/videoJsEvents'
import storage from '../utils/storage'
import * as storageKeys from '../../../configs/storageKeys'

/**
 * Initialize the plugin.
 * @param {object} [options] configuration for the plugin
 */
const Index = function (options) {
  const settings = videojs.mergeOptions(resolutionSwitcherDefaults, options)
  const player = this
  const groupedSrc = {}
  const currentSources = {}
  const currentResolutionState = {}

  /**
   * Updates player sources or returns current source URL
   * @param   {Array}  [src] array of sources [{src: '', type: '', label: '', res: ''}]
   * @returns {Object|String|Array} videojs player object if used as setter or current source URL, object, or array of sources
   */
  player.updateSrc = function (src) {
    //Return current src if src is not given
    if (!src) { return player.src() }

    // Only add those sources which we can (maybe) play
    src = src.filter(function (source) {
      try {
        return (player.canPlayType(source.type) !== '')
      } catch (e) {
        // If a Tech doesn't yet have canPlayType just add it
        return true
      }
    })

    this.currentSources = src.reduce((acc, v) => ({ ...acc, [v.resolution]: { ...v } }), {})
    this.groupedSrc = bucketSources(this.currentSources)
    let chosen = chooseSrc(this.groupedSrc)

    this.currentResolutionState = {
      label: chosen.label,
      sources: chosen.sources
    }

    player.trigger(videojsEvents.UpdateSources)
    player.setSourcesSanitized(chosen.sources, chosen.label)
    player.trigger(videojsEvents.ResolutionChange)

    return player
  }

  /**
   * Returns current resolution or sets one when label is specified
   * @param {String}   [resolution]         resolution name
   * @param {Function} [customSourcePicker] custom function to choose source. Takes 2 arguments: sources, label. Must return player object.
   * @returns {Object}   current resolution object {label: '', sources: []} if used as getter or player object if used as setter
   */
  player.currentResolution = function (resolution, customSourcePicker) {
    if (resolution === null || resolution === undefined) { return this.currentResolutionState }

    // Lookup sources for label
    if (!this.groupedSrc || !this.groupedSrc.resolution || !this.groupedSrc.resolution[resolution]) {
      return
    }

    const sources = this.groupedSrc.resolution[resolution]
    // Remember player state
    const currentTime = player.currentTime()
    const isPaused = player.paused()

    // Hide bigPlayButton
    if (!isPaused && this.player_.options_.bigPlayButton) {
      this.player_.bigPlayButton.hide()
    }

    // Change player source and wait for loadeddata event, then play video
    // loadedmetadata doesn't work right now for flash.
    // Probably because of https://github.com/videojs/video-js-swf/issues/124
    // If player preload is 'none' and then loadeddata not fired. So, we need timeupdate event for seek handle (timeupdate doesn't work properly with flash)
    let handleSeekEvent = 'loadeddata'
    if (this.player_.preload() === 'none') {
      handleSeekEvent = 'timeupdate'
    }

    player
      .setSourcesSanitized(sources, resolution, customSourcePicker || settings.customSourcePicker)
      .one(handleSeekEvent, function () {
        if (isPaused) {
          player.play()
          player.pause()
        }

        player.currentTime(currentTime)

        player.trigger(videojsEvents.ResolutionChange)
      })

    return player
  }

  /**
   * Returns grouped sources by label, resolution and type
   * @returns {Object} grouped sources: { label: { key: [] }, res: { key: [] }, type: { key: [] } }
   */
  player.getGroupedSrc = function () {
    return this.groupedSrc
  }

  player.setSourcesSanitized = function (sources, label, customSourcePicker) {
    this.currentResolutionState = {
      label: label,
      sources: sources,
      resolution: sources.resolution
    }

    if (typeof customSourcePicker === 'function') {
      return customSourcePicker(player, sources, label)
    }

    player.src({ src: sources.src, type: sources.type, resolution: sources.resolution })

    return player
  }

  /**
   * Group sources by label, resolution and type
   * @param   {Array}  src Array of sources
   * @returns {Object} grouped sources: { label: { key: [] }, resolution: { key: [] }, type: { key: [] } }
   */
  function bucketSources (src) {
    const resolutions = {
      label: {},
      type: {},
      resolution: {},
    }

    Object.entries(src)
      .forEach(([, source]) => {
        Object.keys(resolutions)
          .forEach(key => resolutions[key][source[key]] = source)
      })

    return resolutions
  }

  /**
   * Choose src if option.default is specified
   * @param   {Object} groupedSrc {res: { key: [] }}
   * @returns {Object} {res: string, sources: []}
   */
  function chooseSrc(groupedSrc) {
    let selectedResolution = ''
    let selectedLabel = ''

    const chosenUserResolution = storage.get(storageKeys.ResolutionSwitcher.ChosenUserResolution)
    if (chosenUserResolution && groupedSrc.resolution[chosenUserResolution]) {
      selectedResolution = chosenUserResolution
      selectedLabel = groupedSrc.resolution[chosenUserResolution].label
    } else {
      selectedResolution = settings['default']
      selectedLabel = groupedSrc.resolution[selectedResolution].label
    }

    return {
      res: selectedResolution,
      label: selectedLabel,
      sources: groupedSrc.resolution[selectedResolution]
    }
  }

  player.ready(function () {
    if (settings.ui) {
      const menuButton = new ResolutionMenuButton(player, settings)

      player.controlBar.resolutionSwitcher = player.controlBar.el_.insertBefore(menuButton.el_, player.controlBar.getChild('fullscreenToggle').el_)
      player.controlBar.resolutionSwitcher.dispose = function () {
        this.parentNode.removeChild(this)
      }
    }

    if (player.options_.sources.length > 1) {
      // tech: Html5 and Flash
      // Create resolution switcher for videos form <source> tag inside <video>
      player.updateSrc(player.options_.sources)
    }
  })

}

export default Index
