import storage from '../utils/storage'
import volumePersisterDefaults from '../../../configs/volumePersisterDefaults'
import videojsEvents from '../../../configs/videoJsEvents'
import * as StorageKeys from '../../../configs/storageKeys'

function Index (options) {
  const settings = { ...volumePersisterDefaults, ...options }
  const [volumeKey, muteKey] = [
    StorageKeys.VolumePersister.Volume(settings.storageNamespace),
    StorageKeys.VolumePersister.Mute(settings.storageNamespace),
  ]

  this.on(videojsEvents.VolumeChange, () => {
    storage.set(volumeKey, this.volume())
    storage.set(muteKey, this.muted())
  })

  this.ready(() => {
    const persistedVolume = storage.get(volumeKey)
    const persistedMute = storage.get(muteKey)
    this.volume(settings.volume)

    if (typeof persistedVolume !== 'undefined') {
      this.volume(persistedVolume)
    }

    if (typeof persistedMute !== 'undefined') {
      this.muted(persistedMute === true)
    }
  })
}

export default Index
