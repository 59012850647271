<template>
  <div class="player">
    <video class="video-js vjs-big-play-centered" ref="videoPlayer" width="100%" height="100%">
      <p class="vjs-no-js">
        To view this video please enable JavaScript, and consider upgrading to a web browser that
        <a href="https://videojs.com/html5-video-support/" target="_blank">
          supports HTML5 video
        </a>
      </p>
    </video>
  </div>
</template>

<script>
import videojs from 'video.js'
import playerConfig from '../js/VideoJs/playerConfig.js'
import app from '../configs/app.js'

export default {
  name: 'VideoPlayer',
  props: {
    options: {
      type: Object,
      default() {
        return {};
      }
    },
    videoId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      player: null,
      sources: []
    }
  },
  mounted() {
    this.$on('video-loaded', function (video) {
      let sources = [];

      if (typeof video.custom1 !== 'undefined' && video.custom1.length > 0) {
        let playerData = JSON.parse(video.custom1)

        sources = playerData.videos.map((el) => {
          return {
            type: el.type || '',
            src: el.src || '',
            label: el.format || '',
            resolution: el.format || ''
          }
        })
      }

      this.initPlayer(sources)
    })

    this.loadSources(this.videoId)
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose()
    }
  },
  methods: {
    initPlayer (sources = []) {
      this.player = videojs(this.$refs.videoPlayer, playerConfig, function () {
        if (sources.length > 0) {
          this.updateSrc(sources)
        }
      })
    },
    loadSources(videoId) {
      const url = app.getVideoUrl(videoId);

      fetch(url, {
        method: 'GET',
      })
        .then(function(response) {
          if(!response.ok) {
            throw new Error(response.statusText);
          }

          return response;
        })
        .then(response => response.json())
        .then((response) => {
          let result = response.result;
          let video = result.video;

          /*if (errors.length > 0) {
            errors.forEach((el) => {
              this.errors.push(el);
            });

            return;
          }*/

          this.$emit('video-loaded', video);
        })
        .catch(err => this.errors.push(err.message));
    }
  }
}
</script>
