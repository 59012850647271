export const VolumePersister = {
  Volume: storageNamespace => `${storageNamespace}-volume`,
  Mute: storageNamespace => `${storageNamespace}-mute`,
}

export const ResolutionSwitcher = {
  ChosenUserResolution: 'resolution_switcher.chosen_user'
}

export const Ad = {
  FirstShowTimestamp: 'ad.firstShowTimestamp',
}
